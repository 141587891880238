<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="block">
            <div class="inline-block col-6 md:col-6">
              <label for="cnpj" class="required">CNPJ</label>
              <InputMask
                id="cnpj"
                v-model="consignataria.cnpj"
                mask="99.999.999/9999-99"
                :class="{
                  'p-invalid': submitted && v$.consignataria.cnpj.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.cnpj.required.$invalid"
                class="p-error">
                O campo CNPJ é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="razao_social" class="required">Razão Social</label>
              <InputText
                id="razao_social"
                v-model="consignataria.razaoSocial"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.razaoSocial.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.razaoSocial.required.$invalid
                "
                class="p-error">
                O campo Razão Social é obrigatório.
              </div>
              <div
                v-if="
                  submitted && v$.consignataria.razaoSocial.maxLength.$invalid
                "
                class="p-error">
                O campo Nome Fantasia tem o limite máximo de 200 caracteres
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="nome_fantasia" class="required">Nome Fantasia</label>
              <InputText
                id="nome_fantasia"
                v-model="consignataria.nomeFantasia"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.nomeFantasia.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.nomeFantasia.required.$invalid
                "
                class="p-error">
                O campo Nome Fantasia é obrigatório.
              </div>
              <div
                v-if="
                  submitted && v$.consignataria.nomeFantasia.maxLength.$invalid
                "
                class="p-error">
                O campo Nome Fantasia tem o limite máximo de 200 caracteres
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="cep" class="required">CEP</label>
              <InputMask
                id="cep"
                v-model="consignataria.cep"
                type="text"
                mask="99999-999"
                :class="{
                  'p-invalid': submitted && v$.consignataria.cep.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.cep.required.$invalid"
                class="p-error">
                O campo CEP é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="logradouro" class="required">Logradouro</label>
              <InputText
                id="logradouro"
                v-model="consignataria.logradouro"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.logradouro.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.logradouro.required.$invalid
                "
                class="p-error">
                O campo Logradouro é obrigatório.
              </div>
              <div
                v-if="
                  submitted && v$.consignataria.logradouro.maxLength.$invalid
                "
                class="p-error">
                O campo Logradouro tem o limite máximo de 200 caracteres
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="numero" class="required">Número</label>
              <InputNumber
                id="numero"
                v-model="consignataria.numero"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.numero.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.numero.required.$invalid"
                class="p-error">
                O campo Número é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="complemento">Complemento</label>
              <InputText
                id="complemento"
                v-model="consignataria.complemento"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.complemento.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.complemento.maxLength.$invalid
                "
                class="p-error">
                O campo Complemento tem o limite máximo de 100 caracteres
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="bairro" class="required">Bairro</label>
              <InputText
                id="bairro"
                v-model="consignataria.bairro"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.bairro.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.bairro.required.$invalid"
                class="p-error">
                O campo Bairro é obrigatório.
              </div>
              <div
                v-if="submitted && v$.consignataria.bairro.maxLength.$invalid"
                class="p-error">
                O campo Bairro tem o limite máximo de 100 caracteres
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="cidade" class="required">Cidade</label>
              <InputText
                id="cidade"
                v-model="consignataria.cidade"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.cidade.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.cidade.required.$invalid"
                class="p-error">
                O campo Cidade é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="uf" class="required">UF</label>
              <Dropdown
                id="uf"
                v-model="uf"
                :class="{ 'p-invalid': submitted && v$.uf.$invalid }"
                :options="ufs"
                :filter="true"
                filterPlaceholder="Procure pela UF"
                optionLabel="name" />
              <div v-if="submitted && v$.uf.required.$invalid" class="p-error">
                O campo UF é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="telefone" class="required">Telefone</label>
              <InputMask
                id="telefone"
                v-model="consignataria.telefone"
                mask="(99)99999-9999"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.telefone.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.telefone.required.$invalid"
                class="p-error">
                O campo Telefone é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="email" class="required">E-mail</label>
              <InputText
                id="email"
                v-model="consignataria.email"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.email.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.email.required.$invalid"
                class="p-error">
                O campo E-mail é obrigatório.
              </div>
              <div
                v-if="submitted && v$.consignataria.email.maxLength.$invalid"
                class="p-error">
                O campo E-mail tem o limite máximo de 150 caracteres
              </div>
              <div
                v-if="submitted && v$.consignataria.email.email.$invalid"
                class="p-error">
                O campo E-mail está num formato inválido.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="email_financeiro" class="required"
                >E-mail Financeiro</label
              >
              <InputText
                id="email_financeiro"
                v-model="consignataria.emailFinanceiro"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.emailFinanceiro.$invalid,
                }" />
              <div
                v-if="
                  submitted &&
                  v$.consignataria.emailFinanceiro.required.$invalid
                "
                class="p-error">
                O campo E-mail Financeiro é obrigatório.
              </div>
              <div
                v-if="
                  submitted &&
                  v$.consignataria.emailFinanceiro.maxLength.$invalid
                "
                class="p-error">
                O campo E-mail Financeiro tem o limite máximo de 150 caracteres
              </div>
              <div
                v-if="
                  submitted && v$.consignataria.emailFinanceiro.email.$invalid
                "
                class="p-error">
                O campo E-mail Financeiro está num formato inválido.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="data_inicio_contrato" class="required"
                >Data de Início do Contrato</label
              >
              <Calendar
                id="data_inicio_contrato"
                v-model="dataInicioContrato"
                :class="{
                  'p-invalid':
                    submitted &&
                    (v$.dataInicioContrato.$invalid || dataInicioInvalida),
                }"
                :showIcon="true"
                dateFormat="dd/mm/yy" />
              <div
                v-if="submitted && v$.dataInicioContrato.required.$invalid"
                class="p-error">
                O campo Data de Início do Contrato é obrigatório.
              </div>
              <div v-if="dataInicioInvalida" class="p-error">
                O campo Data de Ínicio do Contrato está incorreto.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="data_fim_contrato" class="required"
                >Data de Fim do Contrato</label
              >
              <Calendar
                id="data_fim_contrato"
                v-model="dataFimContrato"
                :class="{
                  'p-invalid':
                    submitted &&
                    (v$.dataFimContrato.$invalid ||
                      dataInvalida ||
                      dataFimInvalida),
                }"
                :showIcon="true"
                dateFormat="dd/mm/yy" />
              <div
                v-if="submitted && v$.dataFimContrato.required.$invalid"
                class="p-error">
                O campo Data de Fim do Contrato é obrigatório.
              </div>
              <div v-if="dataInvalida" class="p-error">
                O campo Data de Fim do Contrato precisa ser maior ou igual ao
                campo de Ínicio de Contrato.
              </div>
              <div v-if="dataFimInvalida" class="p-error">
                O campo Data de Fim do Contrato está incorreto.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="numero_convenio" class="required"
                >Numero do Convênio</label
              >
              <InputText
                id="numero_convenio"
                v-model="consignataria.numeroConvenio"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.numeroConvenio.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.numeroConvenio.required.$invalid
                "
                class="p-error">
                O campo Número do Convênio é obrigatório.
              </div>
              <div
                v-if="
                  submitted &&
                  v$.consignataria.numeroConvenio.maxLength.$invalid
                "
                class="p-error">
                O campo Número do Convênio tem o limite máximo de 20 caracteres
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="tipo_cobranca" class="required">Tipo Cobrança</label>
              <InputText
                id="tipo_cobranca"
                v-model="consignataria.tipoCobranca"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.tipoCobranca.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.tipoCobranca.required.$invalid
                "
                class="p-error">
                O campo Tipo Cobrança é obrigatório.
              </div>
            </div>
            <div
              v-if="$auth.hasRoleGestao()"
              class="inline-block col-6 md:col-6">
              <div><label for="ativo">Status</label></div>
              <InputSwitch id="ativo" v-model="consignataria.ativo" />
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, maxLength, email } from '@vuelidate/validators'
import moment from 'moment'
import Consignataria from '@/domain/Consignataria.js'
import ConsignatariaService from '@/service/ConsignatariaService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    proposconsignataria: {
      type: Object,
      required: false,
      default: new Consignataria(),
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      consignataria: new Consignataria(),
      dataInicioContrato: null,
      dataFimContrato: null,
      dataInvalida: false,
      dataFimInvalida: false,
      dataInicioInvalida: false,
      saving: false,
      confirmDialog: false,
      submitted: false,
      uf: {},
      ufs: [
        { name: 'AC', code: 'AC' },
        { name: 'AL', code: 'AL' },
        { name: 'AM', code: 'AM' },
        { name: 'AP', code: 'AP' },
        { name: 'BA', code: 'BA' },
        { name: 'CE', code: 'CE' },
        { name: 'DF', code: 'DF' },
        { name: 'ES', code: 'ES' },
        { name: 'GO', code: 'GO' },
        { name: 'MA', code: 'MA' },
        { name: 'MG', code: 'MG' },
        { name: 'MS', code: 'MS' },
        { name: 'MT', code: 'MT' },
        { name: 'PA', code: 'PA' },
        { name: 'PB', code: 'PB' },
        { name: 'PE', code: 'PE' },
        { name: 'PI', code: 'PI' },
        { name: 'PR', code: 'PR' },
        { name: 'RJ', code: 'RJ' },
        { name: 'RN', code: 'RN' },
        { name: 'RO', code: 'RO' },
        { name: 'RR', code: 'RR' },
        { name: 'RS', code: 'RS' },
        { name: 'SC', code: 'SC' },
        { name: 'SE', code: 'SE' },
        { name: 'SP', code: 'SP' },
        { name: 'TO', code: 'TO' },
      ],
    }
  },

  validations() {
    return {
      consignataria: {
        cnpj: { required },
        razaoSocial: { required, maxLength: maxLength(200) },
        nomeFantasia: { required, maxLength: maxLength(200) },
        cep: { required },
        logradouro: { required, maxLength: maxLength(200) },
        numero: { required },
        bairro: { required, maxLength: maxLength(100) },
        cidade: { required },
        complemento: { maxLength: maxLength(100) },
        telefone: { required },
        email: { email, required, maxLength: maxLength(150) },
        emailFinanceiro: { email, required, maxLength: maxLength(150) },
        numeroConvenio: { required, maxLength: maxLength(20) },
        tipoCobranca: { required },
      },
      uf: { required },
      dataInicioContrato: { required },
      dataFimContrato: { required },
    }
  },

  created() {
    this.service = new ConsignatariaService(this.$http)
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar consignatária' : 'Nova consignatária'
    },

    fechar() {
      this.limparDatas()
      this.$emit('update:visible', false)
    },

    onShow() {
      if (this.proposconsignataria) {
        this.consignataria = JSON.parse(
          JSON.stringify(this.proposconsignataria),
        )
        if (this.proposconsignataria.id) {
          this.consignataria.numero = Number(this.consignataria.numero)
          this.obj = {
            name: this.consignataria.uf,
            code: this.consignataria.uf,
          }
          this.uf = this.obj
          this.formatarData()
        }
      }
    },

    onHide() {
      this.submitted = false
    },

    formatarData() {
      if (
        this.consignataria.dataInicioContrato != null &&
        this.consignataria.dataInicioContrato != ''
      ) {
        this.dataInicioContrato = moment(
          this.consignataria.dataInicioContrato,
          'YYYY-MM-DD',
        ).toDate()
      }

      if (
        this.consignataria.dataFimContrato != null &&
        this.consignataria.dataFimContrato != ''
      ) {
        this.dataFimContrato = moment(
          this.consignataria.dataFimContrato,
          'YYYY-MM-DD',
        ).toDate()
      }
    },

    validate() {
      this.submitted = true
      this.v$.consignataria.$touch()
      if (
        (this.validaDataInicio() && this.validaDataFim()) ||
        this.validaDataInicio() ||
        this.validaDataFim()
      ) {
        return
      }

      if (this.dataInicioContratoIsMenorDataFimContrato()) {
        this.exibeToast('data-inicio-maior')
        this.dataInvalida = true
        return
      }
      this.dataInvalida = false
      if (
        this.v$.consignataria.$invalid ||
        this.v$.dataInicioContrato.$invalid ||
        this.v$.uf.$invalid ||
        this.v$.dataFimContrato.$invalid
      ) {
        return
      } else {
        this.salvar()
      }
    },

    validaDataInicio() {
      if (!moment(this.dataInicioContrato).isValid()) {
        this.dataInicioInvalida = true
        return true
      } else {
        this.dataInicioInvalida = false
        return false
      }
    },

    validaDataFim() {
      if (!moment(this.dataFimContrato).isValid()) {
        this.dataFimInvalida = true
        return true
      } else {
        this.dataFimInvalida = false
        return false
      }
    },

    dataInicioContratoIsMenorDataFimContrato() {
      return moment(this.dataInicioContrato).isAfter(this.dataFimContrato)
    },

    moment: function () {
      return moment()
    },

    salvar() {
      this.saving = true
      this.atribuirDados()
      this.service
        .saveConsignataria(this.consignataria)
        .then((res) => {
          this.$emit('onUpdate', res.data)
          if (this.consignataria.id) {
            this.exibeToast('success', 'Consignatária atualizada com sucesso')
          } else {
            this.exibeToast('success', 'Consignatária cadastrada com sucesso')
          }
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
        .finally(() => {
          this.saving = false
          this.consignataria = new Consignataria()
          this.submitted = false
          this.fechar()
        })
    },

    atribuirDados() {
      if (this.consignataria.id == null && this.$auth.hasRoleGestaoEntidade()) {
        this.consignataria.ativo = false
      }
      this.consignataria.cnpj = this.consignataria.cnpj.replace(/[^0-9]/g, '')
      this.consignataria.cep = this.consignataria.cep.replace(/[^0-9]/g, '')
      this.consignataria.uf = this.uf.name
      this.consignataria.dataInicioContrato = this.dataInicioContrato
      this.consignataria.dataFimContrato = this.dataFimContrato
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },

    limparDatas() {
      this.dataInicioContrato = null
      this.dataFimContrato = null
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}

.p-card {
  box-shadow: none;
}

.p-dialog .p-card .p-card-body {
  padding: 0rem !important;
}

.break-line {
  white-space: break-spaces;
}
</style>
