export default class BloqueioConsignataria {
  constructor(
    id = null,
    dataBloqueio = null,
    motivoBloqueio = null,
    dataDesbloqueio = null,
    motivoDesbloqueio = null,
    consignataria = null,
  ) {
    ;(this.id = id),
      (this.dataBloqueio = dataBloqueio),
      (this.motivoBloqueio = motivoBloqueio),
      (this.dataDesbloqueio = dataDesbloqueio),
      (this.motivoDesbloqueio = motivoDesbloqueio),
      (this.consignataria = consignataria)
  }
}
