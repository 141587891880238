export default class Consignataria {
  constructor(
    id = null,
    cnpj = null,
    razaoSocial = null,
    nomeFantasia = null,
    cep = null,
    logradouro = null,
    numero = null,
    complemento = null,
    bairro = null,
    cidade = null,
    uf = null,
    telefone = null,
    email = null,
    emailFinanceiro = null,
    dataInicioContrato = null,
    dataFimContrato = null,
    numeroConvenio = null,
    tipoCobranca = null,
    ativo = true,
  ) {
    this.id = id
    this.cnpj = cnpj
    this.razaoSocial = razaoSocial
    this.nomeFantasia = nomeFantasia
    this.cep = cep
    this.logradouro = logradouro
    this.numero = numero
    this.complemento = complemento
    this.bairro = bairro
    this.cidade = cidade
    this.uf = uf
    this.telefone = telefone
    this.email = email
    this.emailFinanceiro = emailFinanceiro
    this.dataInicioContrato = dataInicioContrato
    this.dataFimContrato = dataFimContrato
    this.numeroConvenio = numeroConvenio
    this.tipoCobranca = tipoCobranca
    this.ativo = ativo
  }
}
