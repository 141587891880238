export default class BloqueioConsignatariaService {
  constructor(http) {
    this._http = http
  }

  async getListaBloqueioConsignatariasById(consignatariaId) {
    const { data } = await this._http.get(
      `/api/bloqueio_consignataria/consignataria/${consignatariaId}`,
    )
    return data
  }

  async saveBloqueioConsignataria(desbloqueios, bloqueios) {
    const payload = {
      desbloqueios: desbloqueios,
      bloqueios: bloqueios,
    }
    return await this._http.post('/api/bloqueio_consignataria', payload)
  }
}
