<template>
  <div>
    <Card>
      <template #title>Consignatárias</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="preencheConsignatarias"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="[
            'cnpj',
            'razaoSocial',
            'nomeFantasia',
            'status',
          ]"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignatárias"
          responsiveLayout="scroll">
          <template #empty> Nenhuma consignatária encontrada. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                :value="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%"
                @input="sanitizeFiltros" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-success float-right"
              @click="showFormConsignataria()" />
          </template>

          <Column field="cnpj" header="CNPJ"></Column>
          <Column field="razaoSocial" header="Razão Social"></Column>
          <Column field="nomeFantasia" header="Nome Fantasia"></Column>
          <Column header="Status">
            <template #body="{ data }">
              <Chip
                v-tooltip.left="
                  data.status === 'SUSPENSO'
                    ? formataSuspensaoEntidades(data)
                    : null
                "
                :label="data.status"
                :class="{
                  foraUso: data.status !== 'ATIVO',
                  emUso: data.status === 'ATIVO',
                }">
              </Chip>
            </template>
          </Column>
          <Column header="Ações">
            <template #body="{ data }">
              <Button
                v-tooltip="'Rubricas'"
                icon="pi pi-money-bill"
                class="p-button-rounded p-button-success mr-2"
                @click="rubrica(data)" />

              <Button
                v-if="$auth.hasRoleGestao()"
                v-tooltip.left="'Editar'"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="showFormConsignataria(data)" />

              <Button
                v-if="$auth.hasRoleGestao()"
                v-tooltip.left="'Contratos'"
                icon="pi pi-id-card"
                class="p-button-rounded p-button-submit mr-2"
                @click="contratos(data)" />
              <Button
                v-if="!data.suspensaoEntidades.length"
                v-tooltip.left="'Suspender consignatária'"
                icon="pi pi-pause"
                class="p-button-rounded p-button-danger mr-2"
                @click="carregarHistoricoBloqueio(data)" />
              <Button
                v-if="data.suspensaoEntidades.length"
                v-tooltip.left="'Desbloquear consignatária'"
                icon="pi pi-play"
                class="p-button-rounded p-button-success mr-2"
                @click="carregarHistoricoBloqueio(data)" />
              <!-- <Button
                  v-tooltip.left="'Excluir'"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger mr-2"
                  @click="confirmDeleteConsignataria(slotProps.data)" /> -->
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>
    <Dialog
      modal
      header="Header"
      :style="{ width: '350px' }"
      :visible.sync="deleteConsignatariaDialog">
      <template #header>
        <h3>Excluir Consignataria</h3>
      </template>

      Confirma a exclusão de {{ consignataria.nome_fantasia }} ?

      <template #footer>
        <Button
          label="Não"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteConsignatariaDialog = false" />
        <Button
          label="Sim"
          icon="pi pi-check"
          autofocus
          @click="deleteConsignataria" />
      </template>
    </Dialog>

    <ConsignatariaFormDialog
      :visible.sync="showConsignatariaFormDialog"
      :proposconsignataria="consignataria"
      :update="update"
      @onUpdate="onUpdateConsignataria" />

    <Dialog :visible.sync="historicoDialog" :modal="true" class="p-fluid">
      <template #header>
        <div class="flex justify-content-center">
          <p class="flex align-items-center">Histórico de Suspensões</p>
        </div>
      </template>
      <DataTable
        :value="historicoBloqueios"
        :paginator="true"
        :rows="5"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} bloqueios de matrícula">
        <Column header="Bloqueado Em">
          <template #body="{ data }">
            {{ data.dataBloqueio | formatarData }}
          </template>
        </Column>

        <Column header="Bloqueado Por">
          <template #body="{ data }">
            {{ data.bloqueadoPor ? data.bloqueadoPor : ' - ' }}
          </template>
        </Column>

        <Column header="Entidade da Suspensão">
          <template #body="{ data }">
            {{ data.entidade ? data.entidade.nome : '-' }}
          </template>
        </Column>

        <Column field="Motivo do Bloqueio">
          <template #body="{ data }">
            {{ data.motivoBloqueio ? data.motivoBloqueio : ' - ' }}
          </template>
        </Column>

        <Column header="Desbloqueado Em">
          <template #body="{ data }">
            {{ data.dataDesbloqueio | formatarData }}
          </template>
        </Column>

        <Column field="desbl" header="Desbloqueado Por">
          <template #body="{ data }">
            {{ data.desbloqueadoPor ? data.desbloqueadoPor : ' - ' }}
          </template>
        </Column>

        <Column field="Motivo do Desbloqueio">
          <template #body="{ data }">
            {{ data.motivoDesbloqueio ? data.motivoDesbloqueio : ' - ' }}
          </template>
        </Column>
      </DataTable>
      <template #footer>
        <Button
          v-if="consignataria.suspensaoEntidades.length"
          label="Abrir menu de Desbloqueio"
          icon="pi pi-pause-circle"
          class="p-button-sm p-button-success mr-2"
          @click="abrirSuspensoes(consignataria.id)" />
        <Button
          v-else
          label="Abrir menu de Suspensão"
          icon="pi pi-play-circle"
          class="p-button-sm p-button-danger ml-2"
          @click="abrirSuspensoes(consignataria.id)" />
      </template>
    </Dialog>
    <Dialog
      :visible.sync="bloqueioDialog"
      header="Suspender Consignatária"
      :modal="true"
      class="p-fluid">
      <PickList
        v-model="entidadesPicklist"
        listStyle="width:100%; height:300px"
        source-header="Ativas"
        target-header="Suspensas">
        <template #sourceheader> Ativas </template>
        <template #targetheader> Suspensas </template>
        <template #item="entidade">
          <span>{{ entidade.item.nome }} </span>
        </template>
      </PickList>
      <div class="inline-block col-12 md:col-12">
        <label for="motivo" class="required">Motivo</label>
        <Textarea
          id="motivo"
          v-model="motivo"
          :autoResize="true"
          :class="{
            'p-invalid': enviado && v$.motivo.required.invalid,
          }" />
        <div v-if="enviado && v$.motivo.required.$invalid" class="p-error">
          O campo motivo é obrigatório
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="fecharNovoBloqueio" />
        <Button
          label="Salvar"
          icon="pi pi-check"
          class="p-button-text"
          @click="salvar" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api/'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ConsignatariaFormDialog from './ConsignatariaFormDialog.vue'
import { consignatariaStore } from '@/stores/consignataria'
import ConsignatariaService from '@/service/ConsignatariaService.js'
import EntidadeService from '@/service/EntidadeService.js'
import BloqueioConsignataria from '@/domain/BloqueioConsignataria.js'
import BloqueioConsignatariaService from '@/service/BloqueioConsignatariaService.js'

export default {
  components: {
    ConsignatariaFormDialog,
  },

  setup() {
    const store = consignatariaStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      filtros: {},
      consignatarias: [],
      consignataria: {},
      deleteConsignatariaDialog: false,
      loading: false,
      showConsignatariaFormDialog: false,
      update: false,
      bloqueioConsignataria: new BloqueioConsignataria(),
      historicoBloqueios: [],
      motivo: null,
      historicoDialog: false,
      bloqueioDialog: false,
      desbloqueioDialog: false,
      enviado: false,
      entidadesPicklist: null,
    }
  },

  validations() {
    return {
      motivo: {
        required,
      },
    }
  },
  computed: {
    preencheConsignatarias() {
      if (!this.consignatarias || this.consignatarias.length === 0) {
        return []
      }
      return this.consignatarias.map((consignataria) => ({
        ...consignataria,
        status:
          consignataria.suspensaoEntidades.length > 0
            ? 'SUSPENSO'
            : consignataria.ativo
            ? 'ATIVO'
            : 'INATIVO',
      }))
    },
  },

  watch: {
    bloqueioDialog(newVal) {
      if (!newVal) {
        this.motivo = null
      }
    },
  },

  created() {
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.entidadeService = new EntidadeService(this.$http)
    this.bloqueioConsignatariaService = new BloqueioConsignatariaService(
      this.$http,
    )
    this.initFiltros()
  },

  mounted() {
    this.carregarDados()
  },
  methods: {
    carregarDados() {
      this.loading = true
      if (this.$auth.hasRoleGestao()) {
        this.consignatariaService.getConsignatarias().then((res) => {
          this.consignatarias = res
          this.loading = false
        })
      } else if (this.$auth.hasRoleGestaoEntidade()) {
        this.consignatariaService
          .getConsignatariasUsuarioGestaoEntidade()
          .then((res) => {
            this.consignatarias = res
            this.loading = false
          })
      }
    },

    sanitizeFiltros(value) {
      const sanitizedValue = value.replace(/[^\w\s]|[-]/g, '')
      this.filtros['global'].value = sanitizedValue
    },

    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          value: '',
          matchMode: FilterMatchMode.CONTAINS,
        },
      }
    },

    confirmDeleteConsignataria(data) {
      this.deleteConsignatariaDialog = true
      this.consignataria = data
    },

    showFormConsignataria(data) {
      if (data) {
        this.consignataria = data
        this.update = true
      } else {
        this.consignataria = {}
        this.update = false
      }
      this.showConsignatariaFormDialog = true
    },

    onUpdateConsignataria(event) {
      let index = this.consignatarias.findIndex((el) => el.id === event.id)
      index != -1
        ? this.$set(this.consignatarias, index, event)
        : this.consignatarias.unshift(event)
    },

    deleteConsignataria() {
      this.deleteConsignatariaDialog = false
      this.consignatariaService
        .deleteConsignataria(this.consignataria)
        .then(() => {
          this.carregarDados()
          this.consignataria = {}
          this.$toast.add({
            severity: 'success',
            summary: 'Excluido com sucesso!',
            life: 10000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    rubrica(data) {
      this.$router.push({
        name: 'consignataria-rubricas',
        params: { idConsignataria: data.id },
      })
    },

    contratos(data) {
      this.$router.push({
        name: 'consignataria-contratos-parcelas',
        params: { idConsignataria: data.id },
      })
    },

    formataSuspensaoEntidades(data) {
      if (data.status === 'SUSPENSO') {
        return data.suspensaoEntidades
          .map((entidade) => entidade.nome)
          .join(', ')
      } else {
        return null
      }
    },

    carregarHistoricoBloqueio(data) {
      this.consignataria = data
      this.bloqueioConsignatariaService
        .getListaBloqueioConsignatariasById(data.id)
        .then((res) => {
          this.historicoBloqueios = res
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Ocorreu um erro. Tente novamente',
            life: 10000,
          })
        })
      this.store.$reset()
      this.store.dados = data
      this.historicoDialog = true
    },

    async abrirSuspensoes(consignatariaId) {
      this.bloqueioConsignataria = new BloqueioConsignataria()
      const entidades =
        await this.entidadeService.getEntidadesPorConsignatariaId(
          consignatariaId,
        )
      const suspensoes = this.historicoBloqueios
      this.carregaPicklist(entidades, suspensoes)
    },

    carregaPicklist(entidades, suspensoes) {
      const source = []
      const target = []
      const suspensoesAtivas = suspensoes
        .filter((suspensao) => suspensao.dataDesbloqueio === null)
        .map((suspensao) => suspensao.entidade.id)

      entidades.sort((a, b) => a.id - b.id)

      entidades.forEach((entidade) => {
        if (suspensoesAtivas.includes(entidade.id)) {
          target.push(entidade)
        } else {
          source.push(entidade)
        }
      })
      this.entidadesPicklist = [source, target]
      this.bloqueioDialog = true
    },

    fecharNovoBloqueio() {
      this.bloqueioDialog = false
      this.enviado = false
      this.motivo = null
    },

    validate() {
      this.enviado = true
      this.v$.motivo.$touch()

      if (this.v$.motivo.$invalid) {
        return false
      } else {
        return true
      }
    },

    salvar() {
      if (this.validate()) {
        const desbloqueios = []
        const bloqueios = []

        this.entidadesPicklist[0].forEach((entidade) => {
          const desbloqueio = new BloqueioConsignataria()
          desbloqueio.entidade = entidade
          desbloqueio.consignataria = this.consignataria
          desbloqueio.motivo = this.motivo
          desbloqueios.push(desbloqueio)
        })
        this.entidadesPicklist[1].forEach((entidade) => {
          const bloqueio = new BloqueioConsignataria()
          bloqueio.entidade = entidade
          bloqueio.consignataria = this.consignataria
          bloqueio.motivo = this.motivo
          bloqueios.push(bloqueio)
        })
        this.bloqueioConsignatariaService
          .saveBloqueioConsignataria(desbloqueios, bloqueios)
          .then(() => {
            this.exibeToast('Bloqueio(s) / Desbloqueio(s)')
            this.carregarHistoricoBloqueio(this.consignataria)
            this.carregarDados()
            this.bloqueioDialog = false
            this.motivo = null
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Ocorreu um erro. Tente novamente',
              life: 10000,
            })
          })
      }
    },
    exibeToast(msg) {
      this.$toast.add({
        severity: 'success',
        summary: `${msg} realizado(s) com sucesso!`,
        life: 10000,
      })
    },
  },
}
</script>

<style>
.foraUso {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
}
.emUso {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
  text-align: center;
}
.p-picklist-source-controls {
  display: none !important;
}

.p-picklist-buttons.p-picklist-target-controls {
  display: none !important;
}
</style>
